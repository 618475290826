import React, {useState} from "react";
import {TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import {Box, Flex, Text} from "rebass";
import {Button, Image} from "rebass/styled-components";
import {useThemeValues} from "hooks/useThemeValues";
import theme from "constants/themes/theme";
import {EN_LOCALE} from "locales/en";
import {DisabledCheckbox, SelectedCheckbox} from "assets/images/index";
import OptionSelector from "./components/OptionSelector";

const styles = {
  section: {
    sx: {
      borderBottom: "1px solid",
      borderColor: "#BBBBBB",
    },
    py: "32px",
  },

  checkboxRow: {
    sx: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  checkboxImage: {
    pr: 13,
  },
  checkboxText: {
    color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
    sx: {
      fontWeight: 700,
    },
  },
  saveButtonContainer: {
    sx: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      py: "18px",
      mt: "32px",
    },
  },
  saveButton: {
    sx: {
      width: "100%",
      textTransform: "uppercase",
    },
    p: "18px",
  },
  preferencesNotesText: {
    color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 600,
  },
  preferencesHeaderText: {
    color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "125%",
    paddingTop: "18px",
  },
  hangDryInstructions: {
    wordWrap: "break-word",
    color: theme.colors.NEW_TEXT_PRIMARY_BLUE,
    pt: "18px",
  },
};

/** @deprecated Use src/components/newOrder/common/Preferences/Form.jsx instead */
const PreferencesForm = ({
  preferences,
  onChangeSelection,
  onSave,
  regularPreferences,
  isActionNeeded = true,
}) => {
  const {primaryColor, filterClass} = useThemeValues();
  const [customerNotes, setCustomerNotes] = useState(regularPreferences?.notes);
  const [isHangDrySelected, setIsHangDrySelected] = useState(
    regularPreferences?.isHangDrySelected
  );
  const [hangDryCustomerInstructions, setHangDryCustomerInstructions] = useState(
    regularPreferences?.hangDryInstructions
  );

  const handleClickHangDry = () => {
    setIsHangDrySelected(!isHangDrySelected);
  };

  const handleSave = () => {
    onSave({customerNotes, isHangDrySelected, hangDryCustomerInstructions});
  };

  return (
    <Flex flexDirection="column" px="20px" height="100%">
      <Text {...styles.preferencesHeaderText}>
        Preferences will be applied to all orders
      </Text>
      {preferences.enabled && !!preferences?.businessCustomerPreferences?.length > 0 && (
        <Box {...styles.section}>
          {preferences.businessCustomerPreferences
            .sort((a, b) =>
              a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1
            )
            .map((pref) => (
              <OptionSelector
                key={pref.id}
                onSelect={onChangeSelection}
                preference={pref}
              />
            ))}
        </Box>
      )}
      {preferences.enabled && preferences.hangDry.enabled === true && (
        <Flex flexDirection="column" {...styles.section}>
          <Flex {...styles.checkboxRow} onClick={handleClickHangDry}>
            <div className={filterClass}>
              <Image
                src={isHangDrySelected ? SelectedCheckbox : DisabledCheckbox}
                {...styles.checkboxImage}
                className="element-to-filter"
                alt={
                  isHangDrySelected
                    ? EN_LOCALE.label.markedCheckbox
                    : EN_LOCALE.label.unmarkedCheckbox
                }
              />
            </div>
            <Text {...styles.checkboxText}>Hang Dry</Text>
          </Flex>
          {isHangDrySelected && (
            <>
              <Text {...styles.hangDryInstructions}>
                {preferences.hangDry.instructions}
              </Text>
              <TextField
                label="Hang Dry Instructions"
                placeholder="e.g. Please hang the 4 collared shirts I included in the black bag."
                minRows={3}
                multiline
                maxRows={5}
                value={hangDryCustomerInstructions}
                onChange={(e) => setHangDryCustomerInstructions(e.target.value)}
                maxLength={255}
                variant="outlined"
                style={{marginTop: "12px"}}
              />
            </>
          )}
        </Flex>
      )}
      <Flex flexDirection="column" {...styles.section} sx={{border: "none"}}>
        <Text {...styles.preferencesNotesText}>Preferences Notes</Text>
        <TextField
          label="Care Preferences"
          placeholder="e.g.  Hypoallergenic, please use bleach for whites"
          minRows={3}
          multiline
          maxRows={5}
          value={customerNotes}
          onChange={(e) => setCustomerNotes(e.target.value)}
          maxLength={255}
          variant="outlined"
          style={{marginTop: "12px"}}
        />
      </Flex>
      {isActionNeeded && (
        <Flex {...styles.saveButtonContainer}>
          <Button
            {...styles.saveButton}
            backgroundColor={primaryColor}
            onClick={handleSave}
          >
            Save Preferences
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

PreferencesForm.propTypes = {
  preferences: PropTypes.object,
  onChangeSelection: PropTypes.func,
  onSave: PropTypes.func,
  regularPreferences: PropTypes.object,
  isActionNeeded: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([undefined])]),
};

PreferencesForm.defaultProps = {
  isActionNeeded: true,
  regularPreferences: {},
  preferences: {},
};

export default PreferencesForm;
