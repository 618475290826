import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {Box, Flex, Button, Text} from "rebass/styled-components";
import {ToastError} from "components/common";
import {Drawer} from "components/common/drawers/Drawer";
import ConfirmAddress from "components/online-order/online-order-form/common/confirm-address";
import {createAddressPayload} from "components/online-order/utils";
import useSaveAddress from "hooks/useSaveAddress";
import {useThemeValues} from "hooks/useThemeValues";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {businessSelectors} from "state/redux/slices/business";
import {orderActions} from "state/redux/slices/order";
import {getCurrentCustomerAddress} from "state/redux/slices/order/selectors";
import {DEFAULT_PRIMARY_COLOR} from "constants/themes/defaultTheme";
import {EN_LOCALE} from "locales/en";

export const EditCustomerAddress = ({businessId, ...props}) => {
  const {isOpen, toggle} = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {primaryColor} = useThemeValues();
  const styles = getStyles(primaryColor);

  const customerAddress = useAppSelector(getCurrentCustomerAddress);
  const businessIdFromState = useAppSelector(businessSelectors.getBusinessId);

  const [isChangeAddressOpen, setIsChangeAddressOpen] = useState(false);

  const {addressObj, loading, setAddressObj, handleAddressChange, saveAddress} =
    useSaveAddress({
      afterSuccess: (address) => {
        dispatch(orderActions.setCurrentAddress(address));
        toggle();
      },
      onFail: (error) => {
        toast.error(<ToastError message={error} />);
      },
    });

  useEffect(() => {
    if (isOpen) {
      setAddressObj(customerAddress);
    }
  }, [customerAddress, isOpen, setAddressObj]);

  const handleSave = () => {
    const payload = createAddressPayload(addressObj);

    saveAddress(payload, addressObj.googlePlacesId);
  };

  return (
    <Drawer
      title={EN_LOCALE.label.confirmAddress}
      isShowingDrawer={isOpen}
      closeDrawer={isChangeAddressOpen ? () => setIsChangeAddressOpen(false) : toggle}
      loading={loading}
    >
      {isChangeAddressOpen ? (
        <Flex {...styles.confirmation}>
          <Box {...styles.confirmation.wrapper}>
            <Text {...styles.confirmation.subtext}>
              All of your current progress will be lost. Are you sure you want to proceed?
            </Text>
          </Box>
          <Flex {...styles.buttons}>
            <Button
              variant="outline"
              mr="10px"
              {...styles.buttons.button}
              onClick={() => setIsChangeAddressOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              {...styles.buttons.button}
              onClick={() => {
                history.push(
                  businessIdFromState || businessId
                    ? `/order/business/${businessIdFromState || businessId}`
                    : "/"
                );
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex {...styles.wrapper}>
          <Box py="18px" mx="20px" height="calc(100vh - 160px)" overflow="auto">
            <ConfirmAddress
              localAddressObj={addressObj}
              handleAddressChange={handleAddressChange}
            />
            <Text
              {...styles.changeAddressText}
              onClick={() => {
                setIsChangeAddressOpen(true);
              }}
            >
              Change my address
            </Text>
          </Box>

          <Flex {...styles.saveButtonContainer}>
            <Button {...styles.saveButton} onClick={() => handleSave()}>
              Update Address
            </Button>
          </Flex>
        </Flex>
      )}
    </Drawer>
  );
};

const getStyles = (primaryColor) => ({
  wrapper: {
    height: "calc(100% - 67px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "auto",
  },
  changeAddressText: {
    sx: {
      pt: "16px",
      fontSize: "14px",
      color: primaryColor || DEFAULT_PRIMARY_COLOR,
      textDecoration: "underline",
      textAlign: "center",
      cursor: "pointer",
    },
    fontWeight: 600,
  },
  saveButtonContainer: {
    sx: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      boxShadow: "0 -5px 8px -7px rgb(0 0 0 / 20%)",
    },
  },
  saveButton: {
    sx: {
      backgroundColor: primaryColor || DEFAULT_PRIMARY_COLOR,
      width: "calc(100% - 40px)",
      textTransform: "uppercase",
    },
    m: "18px 0",
    p: "18px",
  },
  confirmation: {
    height: "calc(100% - 67px)",
    py: "18px",
    mx: "18px",
    flexDirection: " column",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "auto",

    wrapper: {
      m: "0 24px",
    },

    subtext: {
      mt: "4px",
      fontSize: "18px",
      color: "NEW_TEXT_PRIMARY_BLUE",
    },
  },
  buttons: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    my: "18px",

    button: {
      sx: {
        textTransform: "uppercase",
      },
      height: "50px",
      width: "calc(50% - 5px)",
      fontFamily: "Inter",
      fontWeight: 600,
    },
  },
});
