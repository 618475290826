import { AxiosPromise } from "axios";
import { MachineTypes, SelfServiceStatuses } from "constants/index";
import httpClient from "./httpClient";


export interface MachineCard {
  turnId: number;
  machineId: number;
  machineName: string;
  machineType: MachineTypes;
  startedDate: string;
  turnStatus: SelfServiceStatuses;
  deviceName: string;
  deviceStatus: string;
  timeZone: string;
  isDumbDumb: boolean;
}

export interface GetMachineCardsResponse {
  machineCards: MachineCard[];
}

export interface AdditionalVendOnMachineRequirements {
  machineId: number;
  turnId: number;
  businessId: number;
  additionalVendAmount: number;
  storeCustomerId: number;
}

export const getMachineCards: (
  encodedBusinessId: string
) => AxiosPromise<GetMachineCardsResponse> = (encodedBusinessId) => {
  return httpClient({
    method: "GET",
    url: `/live-status/machine/business/${encodedBusinessId}/machine-cards`,
  });
};

export interface GetHashValidateResponse {
  machineId: number;
}

export const validateMachineCode: (
  hash: string,
  businessId: number
) => AxiosPromise<GetHashValidateResponse> = (hash, businessId) => {
  return httpClient({
    method: "GET",
    url: `/live-status/machine/${hash}/validate`,
    params: {businessId},
  });
};

export const processAdditionalVendOnMachine = (
  body: AdditionalVendOnMachineRequirements
) => {
  return httpClient({
    method: "POST",
    url: "/live-status/machine/additional-vend",
    data: body,
  });
};
