import {FC, useEffect, useRef, useState} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {DateTime} from "luxon";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Loader} from "components/common";
import {OptOutInfoBanner, OptOutInfoBannerType} from "components/common/OptOutInfoBanner";
import {Button} from "components/common/buttons/Button";
import DeliveryConflictPopupModal from "components/common/orderBuilder/DeliveryConflictPopupModal";
import {BackButton} from "components/newOrder/common/BackButton";
import {useDeliveryWindowsSubmit} from "hooks/orderBuilder/delivery/useDeliveryWindowsSubmit";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {getBagsCount} from "state/redux/slices/order/selectors/servicesSelector";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {checkSubscriptionEquivalence} from "utils/schedule/checkSubscriptionEquivalence";
import {FETCHING_STATUS} from "constants/api";
import {ERRORS_MESSAGES, OOBO_CONFLICTS_TYPES, VIEWS} from "constants/order";
import {DICTIONARY} from "./assets/dictionary";
import {DIRECTIONS} from "./assets/utils";
import {ManualDeliveryTimeInput} from "./common/ManualDeliveryTimeInput";
import {ScheduleForm} from "./common/ScheduleForm";
import {ScheduleWindow} from "./hooks/useScheduling/types";
import {useScheduling} from "./hooks/useScheduling/useScheduling";
import "./styles/SchedulingScreen.style.scss";
import "./styles/layout.scss";

export const URLS = {
  CURRENT: "schedule",
  NEXT: "checkout",
  PICKUP: "pickup",
  DELIVERY: "delivery",
  SERVICE: "service",
};

export const SchedulingScreen: FC = () => {
  const bagsCount = useAppSelector(getBagsCount);
  const dispatch = useAppDispatch();
  const {serviceMultiselect, recurringSubscriptionsV2} = useFlags();
  const [isShowingConflicts, setIsShowingConflicts] = useState(false);
  const scheduleFormRef = useRef<HTMLFormElement>(null);
  const state = useAppSelector(orderSelectors.getOnlineOrderData);
  const {
    initialOrderData: {
      data: {subscriptions: existingSubscriptions},
    },
    orderBuilder: {
      services: {selectedCategories},
    },
    schedule: {
      pickup,
      returnInfo,
      turnAround,
      subscription: currentSubscription,
      ooboConflicts,
    },
    currentCustomerAddress,
    nearStoresData: {data: availableStores, fetchingStatus: nearStoresStatus},
  } = state;
  const selectedCategoriesNames = selectedCategories?.map((category) => category.name);
  const history = useHistory();
  const {url} = useRouteMatch();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState<boolean>(true);
  /** @deprecated Use transportationPreference instead */
  const isAutoSchedule = Boolean(availableStores?.autoScheduleReturnEnabled);
  const isCommercialPricingTier =
    availableStores?.availableServices?.isCommercialPricingTier;
  const [pickupDeliveryData, setPickupDeliveryData] = useState<typeof DIRECTIONS>({
    ...DIRECTIONS,
  });

  const discount =
    availableStores?.ownDeliveryStore?.recurringDiscountInPercent ||
    availableStores?.onDemandDeliveryStore?.recurringDiscountInPercent;

  const discountBannerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    nearStoresStatus !== FETCHING_STATUS.FULFILLED &&
      history.push(String(url).replace(`/${URLS.CURRENT}`, ""));
  }, [nearStoresStatus, history, url]);

  const gotoPickup = (): void => {
    dispatch(orderActions.setStage(VIEWS.ALL_WINDOWS_PICKUP));
    history.push(`${url}/${URLS.PICKUP}`);
  };
  const gotoDelivery = (): void => {
    dispatch(orderActions.setStage(VIEWS.ALL_WINDOWS_RETURN));
    history.push(`${url}/${URLS.DELIVERY}`);
  };

  const {isEstimating: isDoorDashEstimating} = useScheduling({
    setIsNextButtonDisabled,
    isAutoSchedule: Boolean(isAutoSchedule),
    bagsCount,
    setPickupDeliveryData,
    pickup: pickup as ScheduleWindow,
    delivery: returnInfo as ScheduleWindow,
    selectedServices: selectedCategoriesNames,
    turnAround: Number(turnAround),
    gotoPickup,
    gotoDelivery,
    form: scheduleFormRef.current as HTMLFormElement,
  });

  const validateAndSetSubscription = (interval: string | number | null) => {
    let subscription = {};

    const luxonPickupStart = DateTime.fromISO(String(pickup?.startTime));
    const luxonPickupEnd = DateTime.fromISO(String(pickup?.endTime));
    const luxonReturnStart = DateTime.fromISO(String(returnInfo?.startTime));
    const luxonReturnEnd = DateTime.fromISO(String(returnInfo?.endTime));

    if (interval !== "null") {
      subscription = {
        interval: Number(interval),
        deliveryTimingsId: returnInfo?.id || null,
        pickupTimingsId: pickup?.id,
        pickupWindow: [luxonPickupStart.valueOf(), luxonPickupEnd.valueOf()],
        returnWindow: returnInfo?.startTime
          ? [luxonReturnStart.valueOf(), luxonReturnEnd.valueOf()]
          : [],
        modifierIds: [],
      };

      const isValidSubscription = checkSubscriptionEquivalence({
        existingSubscriptions,
        subscriptionIntent: subscription,
        currentCustomerAddress,
      });

      if (!isValidSubscription) {
        createErrorToast({
          primaryMessage: ERRORS_MESSAGES.subscriptionExistMessage,
          secondaryMessage: ERRORS_MESSAGES.subscriptionExistCTA,
          toastId: "recurring-order-exist",
        });
        return;
      }
    }

    dispatch(orderActions.setSubscription(subscription));
    return subscription;
  };

  const {onScheduleFormSubmit, isNashEstimating, submitDeliveryWindows} =
    useDeliveryWindowsSubmit({
      scheduleFormRef,
      validateAndSetSubscription,
      setIsNextButtonDisabled,
      setIsShowingConflicts,
    });

  useEffect(() => {
    if (currentSubscription?.interval) {
      discountBannerRef.current?.classList.add("lined");
    } else {
      discountBannerRef.current?.classList.remove("lined");
    }
  }, [currentSubscription]);

  const onScheduleFormChange = (): void => {
    const data = new FormData(scheduleFormRef.current as HTMLFormElement);
    const formData = Object.fromEntries(data.entries());

    validateAndSetSubscription(formData.interval as string);
    if (formData.interval !== "null") {
      discountBannerRef.current?.classList.add("lined");
    } else {
      discountBannerRef.current?.classList.remove("lined");
    }
  };

  const isRecurringOrderPossible = !(serviceMultiselect && !recurringSubscriptionsV2);
  return (
    <>
      {isShowingConflicts && (
        <DeliveryConflictPopupModal
          onCancel={() => {
            setIsShowingConflicts(false);
          }}
          onConfirm={submitDeliveryWindows}
          isPickup={ooboConflicts.includes(OOBO_CONFLICTS_TYPES.PICKUP_MAX_STOPS)}
        />
      )}
      {isDoorDashEstimating && <Loader />}
      <main className="scheduling-screen main">
        <section className="scheduling-screen__content">
          <header>
            <BackButton forceBack={true} />
            <h2>{DICTIONARY.PICKUP_AND_DELIVERY}</h2>
          </header>
          <OptOutInfoBanner type={OptOutInfoBannerType.stretch} />
          <ManualDeliveryTimeInput />
          <section className="scheduling-screen__forms">
            <ScheduleForm
              forwardedRef={scheduleFormRef}
              routeData={pickupDeliveryData}
              selectedInterval={currentSubscription?.interval || null}
              onChange={onScheduleFormChange}
            />
            {Number(discount) > 0 &&
              !isCommercialPricingTier &&
              isRecurringOrderPossible && (
                <article
                  ref={discountBannerRef}
                  className="scheduling-screen__forms__info-banner"
                >
                  <h4>{DICTIONARY.BANNER_HEADER(discount)}</h4>
                  <p>{DICTIONARY.BANNER_TEXT(discount)}</p>
                </article>
              )}
          </section>
          <aside className="scheduling-screen__actions">
            <Button
              onClick={onScheduleFormSubmit}
              disabled={isNextButtonDisabled}
              isLoading={isNashEstimating}
              text={DICTIONARY.NEXT}
            />
          </aside>
        </section>
      </main>
    </>
  );
};
