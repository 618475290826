import {FC, ReactNode, useEffect} from "react";
import {useLogout} from "hooks/auth/useLogout";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {customerActions} from "state/redux/slices/customer";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import {fetchCentsCustomer} from "api/customer/fetchCentsCustomer";
import {getCustomerAuthToken} from "api/httpClient";

/**
 * This provider is required to fetch basic customer data
 * into the application state as soon as it is possible.
 */
export const CentsCustomerProvider: FC<{
  children: ReactNode;
}> = ({children}) => {
  const dispatch = useAppDispatch();
  const {logout} = useLogout();
  const centsCustomer = useAppSelector(getCentsCustomer);

  const isCustomerAvailableToFetch = !centsCustomer && getCustomerAuthToken();

  useEffect(
    function getCentsCustomerData() {
      if (isCustomerAvailableToFetch) {
        fetchCentsCustomer()
          .then((response) => {
            dispatch(customerActions.setCentsCustomer(response.data));
          })
          .catch(logout);
      }
    },
    [dispatch, isCustomerAvailableToFetch, logout]
  );

  return <>{children}</>;
};
