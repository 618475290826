import {FC, ReactNode} from "react";
import Header from "components/common/Header";
import {Sidebar} from "components/common/Sidebar";
import {OrderDetailsData} from "components/orderManager/tuneUp/OrderDetails/orderDetails.types";
import useToggle from "hooks/useToggle";
import {IBusinessSettingsData} from "state/redux/slices/business/types";

export const Layout: FC<{
  children?: ReactNode;
  headerName?: string;
  onManageOrderClick?: () => void;
  orderDetails?: OrderDetailsData;
  businessSettings?: IBusinessSettingsData | Record<string, unknown>;
  isLogoNeeded?: boolean;
  showSidebar?: boolean;
  showBackButton?: boolean;
  customLogoClass?: string;
  customHeaderClass?: string;
  isSelfServeHeader?: boolean;
  withoutHeader?: boolean;
  hideManageButton?: boolean;
  isTopOfThePage?: Boolean;
}> = ({
  children,
  headerName = "",
  onManageOrderClick,
  orderDetails,
  businessSettings = {},
  isLogoNeeded = false,
  showSidebar = true,
  showBackButton = false,
  customLogoClass,
  customHeaderClass,
  isSelfServeHeader,
  withoutHeader,
  hideManageButton,
  isTopOfThePage,
}) => {
  const {
    isOpen: sidebarOpen,
    setIsOpen: setSidebarOpen,
    toggle: toggleSidebar,
  } = useToggle();

  return (
    <>
      {showSidebar && (
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          termsOfServiceUrl={
            businessSettings?.isCustomUrl && businessSettings?.termsOfServiceUrl
              ? String(businessSettings.termsOfServiceUrl)
              : undefined
          }
          businessId={businessSettings?.businessId as number}
          businessSettings={businessSettings}
        />
      )}
      {!withoutHeader ? (
        <Header
          onMenuClick={toggleSidebar}
          isLogoNeeded={isLogoNeeded}
          onManageOrderClick={onManageOrderClick}
          order={orderDetails}
          headerName={headerName}
          customLogoClass={customLogoClass}
          customHeaderClass={customHeaderClass}
          isSelfServeHeader={isSelfServeHeader}
          showSidebar={showSidebar}
          showBackButton={showBackButton}
          hideManageButton={hideManageButton}
          isTopOfThePage={isTopOfThePage}
        />
      ) : null}
      {children}
    </>
  );
};
