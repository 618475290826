import {FC, ReactNode} from "react";
import classNames from "classnames";
import {useFlags} from "launchdarkly-react-client-sdk";
import styles from "./SectionLayout.module.scss";

export const SectionLayout: FC<{
  className?: string;
  children: ReactNode;
}> = ({children, className}) => {
  const {manageOrderV2} = useFlags();

  return (
    <div className={classNames({[styles.sectionsWrapper]: manageOrderV2}, className)}>
      {children}
    </div>
  );
};
