import {FC} from "react";
import {Image} from "rebass/styled-components";
import {A11yButton} from "components/common/buttons/A11yButton";
import {getCreditCardBrandIcon} from "utils/payment";
import {EN_LOCALE} from "locales/en";
import {BlueCheckIconSmall, TrashcanIcon} from "assets/images";
import {IPaymentMethod} from "types/customer";
import "./CardItem.styles.scss";

interface CardItemProps {
  paymentMethod: IPaymentMethod;
  onSelection: () => void;
  onDelete: () => void;
}
const trashButtomStyles = (paymentMethod: IPaymentMethod) =>
  paymentMethod.hasServiceOrders ||
  paymentMethod.hasSubscriptions ||
  paymentMethod.notSaved
    ? {...styles.grayOut}
    : {};

const CardItem: FC<CardItemProps> = ({paymentMethod, onSelection, onDelete}) => {
  return (
    <A11yButton
      className="card-item"
      onAction={onSelection}
      aria-pressed={paymentMethod.isDefault}
    >
      <div className="card-info">
        <img
          src={getCreditCardBrandIcon(paymentMethod.brand)}
          alt="card-brand"
          className="card-brand-image"
        />
        <p className="card-number">•••• {paymentMethod.last4}</p>
      </div>
      <div className="card-actions">
        {paymentMethod.isDefault && (
          <Image
            src={BlueCheckIconSmall}
            alt={EN_LOCALE.label.checkMark}
            className="default-card"
          />
        )}
        <A11yButton
          onAction={(e: Event) => {
            e.stopPropagation();
            onDelete();
          }}
          className="outline-distant"
        >
          <Image
            {...trashButtomStyles(paymentMethod)}
            src={TrashcanIcon}
            alt={EN_LOCALE.label.trashCan}
            className="delete-card"
          />
        </A11yButton>
      </div>
    </A11yButton>
  );
};

const styles = {
  grayOut: {
    opacity: 0.5,
  },
};
export default CardItem;
