import React from "react";
import {TextField} from "@material-ui/core";
import {Box, Button, Text} from "rebass/styled-components";
import useToggle from "../../../../hooks/useToggle";
import PreferenceDockForm from "./preferences/DockModalForm";

/** @deprecated Use src/components/common/sectionItems/AutonomousCarePreferences instead */
const NotesAndPreferencesForm = (props) => {
  const {
    readonly,
    businessId,
    storeId,
    customer,
    handleSave,
    orderNotes,
    onOrderNotesChange,
  } = props;
  const {isOpen: showPreferences, toggle: togglePreferences} = useToggle();

  return (
    <>
      <Box>
        <Button
          width={1}
          fontSize="18px"
          variant="outline"
          onClick={togglePreferences}
          fontFamily="Inter"
        >
          My Care Preferences
        </Button>
      </Box>
      <Box mt="32px">
        <Text fontSize="18px" fontWeight={600} color="NEW_TEXT_PRIMARY_BLUE">
          Any specific notes about this order?
        </Text>
        <TextField
          label="Order Notes"
          placeholder="e.g. The grey sweater has a stain on it, please use stain remover."
          minRows={3}
          maxRows={5}
          multiline
          value={orderNotes}
          onChange={onOrderNotesChange}
          disabled={readonly}
          maxLength={255}
          variant="outlined"
          style={{marginTop: "16px"}}
          fullWidth
        />
      </Box>
      <PreferenceDockForm
        toggle={togglePreferences}
        isOpen={showPreferences}
        header={"My Care Preferences"}
        businessId={businessId}
        storeId={storeId}
        customer={customer}
        saveSelections={handleSave}
      />
    </>
  );
};

export default NotesAndPreferencesForm;
