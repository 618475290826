import {FC, ReactNode} from "react";
import classNames from "classnames";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import {EN_LOCALE} from "locales/en";
import {Chevron} from "assets/images";
import styles from "./sectionActionButton.module.scss";

export const SectionActionButton: FC<{
  onClick?: () => void | Promise<void>;
  children?: ReactNode;
  variant?: "primary" | "underlined";
  className?: string;
}> = ({onClick, children, variant = "underlined", className}) => {
  if (variant === "primary") {
    return (
      <div className={classNames(styles.chevron, className)}>
        <img src={Chevron} height={16} width={8} alt={EN_LOCALE.label.chevron} />
      </div>
    );
  }

  if (onClick) {
    return (
      <Button
        onClick={onClick}
        variant={ButtonVariant.UNDERLINED}
        className={classNames(styles.actionButton, styles.rightButton)}
      >
        {children}
      </Button>
    );
  }

  return <></>;
};
