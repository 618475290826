import {useMemo} from "react";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {Box, Flex, Image, Text} from "rebass/styled-components";
import {A11yButton} from "components/common/buttons/A11yButton";
import {AddressInstructionsDockModal} from "components/common/order-sections";
import {EditCustomerAddress} from "components/newOrder/routes/checkout/online-order-form/finishing-up/delivery-details/EditCustomerAddress";
import {sectionStyles} from "components/online-order/online-order-form/finishing-up/styles.js";
import {formatAddress} from "components/online-order/utils.js";
import {useThemeValues} from "hooks/useThemeValues";
import useToggle from "hooks/useToggle";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {orderActions, orderSelectors} from "state/redux/slices/order";
import {formatTimeWindow} from "utils/date";
import {TEXT_ME_WHEN_READY} from "constants/order";
import {INTERVAL_DISPLAY} from "constants/subscriptions";
import {EN_LOCALE} from "locales/en";
import {DateAndTimeIcon, RightChevronIcon, RoutesIcon, TruckIcon} from "assets/images";
import moduleStyles from "./deliveryDetails.module.scss";

export const DeliveryDetails = (props) => {
  const {url} = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {businessId} = props;
  const {translucentPrimaryColor} = useThemeValues();
  const {isOpen: showEditAddressInstuctions, toggle: toggleShowEditAddressInstuctions} =
    useToggle();
  const {isOpen: showEditCustomerAddress, toggle: toggleShowEditCustomerAddress} =
    useToggle();
  const {
    currentCustomerAddress,
    schedule: {subscription},
    orderBuilder: {
      transportation: {pickupDelivery, returnDelivery},
    },
  } = useAppSelector(orderSelectors.getOnlineOrderData);
  const storeTimeZone = useAppSelector(orderSelectors.getStoreTimeZone);

  const pickupWindow = pickupDelivery?.deliveryWindow;
  const deliveryWindow = returnDelivery?.deliveryWindow;

  const windows = {
    pickup: formatTimeWindow(pickupWindow, storeTimeZone),
    delivery: deliveryWindow?.length
      ? formatTimeWindow(deliveryWindow, storeTimeZone)
      : TEXT_ME_WHEN_READY,
  };

  const formattedAddress = useMemo(() => {
    return formatAddress({...currentCustomerAddress, postalCode: ""});
  }, [currentCustomerAddress]);

  const updateSelectedAddress = (address) => {
    dispatch(orderActions.setCurrentAddress(address));
  };

  const onRescheduleClick = () => {
    history.push(url.replace("checkout", "schedule"), location?.state);
  };

  return (
    <Box>
      <Box {...styles.section.header} backgroundColor={translucentPrimaryColor}>
        Delivery Details
      </Box>
      <A11yButton onAction={toggleShowEditCustomerAddress} className={moduleStyles.item}>
        <Box {...styles.section.link.iconWrapper}>
          <Image src={RoutesIcon} alt={EN_LOCALE.label.geolocationPin} />
        </Box>
        <Flex {...styles.section.link.dataWrapper}>
          <Box {...styles.section.link.data}>
            Pickup / Delivery Address
            <Text {...styles.section.link.dataSubText}>{formattedAddress}</Text>
          </Box>
          <Image
            src={RightChevronIcon}
            {...styles.section.link.rightChevron}
            alt={EN_LOCALE.label.rightChevron}
          />
        </Flex>
      </A11yButton>
      <EditCustomerAddress
        isOpen={showEditCustomerAddress}
        toggle={toggleShowEditCustomerAddress}
        businessId={businessId}
      />
      <A11yButton
        className={moduleStyles.item}
        onAction={toggleShowEditAddressInstuctions}
      >
        <Box {...styles.section.link.iconWrapper}>
          <Image src={TruckIcon} alt={EN_LOCALE.label.truck} />
        </Box>
        <Flex {...styles.section.link.dataWrapper}>
          <Box {...styles.section.link.data}>
            Pickup / Delivery Instructions
            <Text {...styles.section.link.dataSubText}>
              {currentCustomerAddress.instructions || (
                <i>Add pickup / delivery instructions</i>
              )}
            </Text>
          </Box>
          <Image
            src={RightChevronIcon}
            {...styles.section.link.rightChevron}
            alt={EN_LOCALE.label.rightChevron}
          />
        </Flex>
      </A11yButton>
      <AddressInstructionsDockModal
        isOpen={showEditAddressInstuctions}
        toggle={toggleShowEditAddressInstuctions}
        selectedAddress={currentCustomerAddress}
        updateSelectedAddress={updateSelectedAddress}
      />

      <A11yButton className={moduleStyles.item} onAction={onRescheduleClick}>
        <Box {...styles.section.link.iconWrapper}>
          <Image src={DateAndTimeIcon} alt={EN_LOCALE.label.clock} />
        </Box>
        <Flex {...styles.section.link.dataWrapper}>
          <Box {...styles.section.link.data}>
            Pickup / Delivery Time
            {windows.pickup?.length ? (
              <>
                <Text {...styles.section.link.dataSubText}>Pickup: {windows.pickup}</Text>
                {windows.delivery?.length ? (
                  <Text {...styles.section.link.dataSubText}>
                    Delivery: {windows.delivery}
                  </Text>
                ) : null}
              </>
            ) : (
              <Text {...styles.section.link.dataSubText}>
                <i>Add pickup / delivery window</i>
              </Text>
            )}
            {subscription?.interval ? (
              <Text {...styles.section.link.recurringText}>
                {INTERVAL_DISPLAY[subscription?.interval]?.toUpperCase()}
              </Text>
            ) : null}
          </Box>
          <Image
            src={RightChevronIcon}
            {...styles.section.link.rightChevron}
            alt={EN_LOCALE.label.rightChevron}
          />
        </Flex>
      </A11yButton>
    </Box>
  );
};

const styles = {
  section: sectionStyles,
  title: {
    fontSize: "16px",
  },
};
