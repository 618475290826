import React from "react";
import {TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import {Flex, Image, Text} from "rebass/styled-components";
import {useThemeValues} from "hooks/useThemeValues";
import {EN_LOCALE} from "locales/en";
import {
  DisabledCheckbox,
  IconEmptyGrey,
  IconSelectedGrey,
  SelectedCheckbox,
} from "../../../../assets/images";

const AddressInstructionsForm = (props) => {
  const {instructions, leaveAtDoor, handleChange, hideLeaveAtDoor, hideNote, disabled} =
    props;

  const {filterClass} = useThemeValues();

  const onHandleClick = () => {
    if (disabled) {
      return;
    } else {
      handleChange("leaveAtDoor", !leaveAtDoor);
    }
  };

  return (
    <Flex {...styles.instructionsFormContainer}>
      <TextField
        minRows={3}
        maxRows={5}
        multiline
        label="Pickup / Delivery Instructions"
        placeholder="e.g. call upon arrival, entrance on right side of building, don't ring bell, etc."
        variant="outlined"
        fullWidth
        value={instructions || ""}
        onChange={(e) => handleChange("instructions", e.target.value)}
        disabled={disabled}
        inputProps={{maxLength: 255}}
      />
      {hideLeaveAtDoor ? null : (
        <Flex {...styles.checkboxRow} onClick={onHandleClick}>
          <Flex className={filterClass}>
            <Image
              src={
                disabled
                  ? leaveAtDoor
                    ? IconSelectedGrey
                    : IconEmptyGrey
                  : leaveAtDoor
                  ? SelectedCheckbox
                  : DisabledCheckbox
              }
              {...styles.checkboxImage}
              alt={
                leaveAtDoor
                  ? EN_LOCALE.label.markedCheckbox
                  : EN_LOCALE.label.unmarkedCheckbox
              }
            />
          </Flex>
          <Text
            {...styles.checkboxText}
            color={!disabled ? "NEW_TEXT_PRIMARY_BLUE" : "NEW_TEXT_GREY"}
          >
            Leave deliveries at my door if I'm not home
          </Text>
        </Flex>
      )}
      {hideNote ? null : (
        <Text {...styles.noteText}>
          We will send you updates about your pickup via text.
        </Text>
      )}
    </Flex>
  );
};

const styles = {
  instructionsFormContainer: {
    sx: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
    },
  },
  checkboxRow: {
    sx: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        filter: "brightness(0)",
      },
    },
  },
  checkboxImage: {
    py: 20,
    pr: 15,
  },
  checkboxText: {
    sx: {
      fontWeight: 500,
    },
  },
  noteText: {
    fontSize: "12px",
    color: "NEW_TEXT_GREY",
    mt: "12px",
  },
};

AddressInstructionsForm.propTypes = {
  instructions: PropTypes.string,
  leaveAtDoor: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  hideLeaveAtDoor: PropTypes.bool,
  hideNote: PropTypes.bool,
};

AddressInstructionsForm.defaultProps = {
  instructions: "",
  leaveAtDoor: false,
  hideLeaveAtDoor: false,
  hideNote: false,
};

export default AddressInstructionsForm;
