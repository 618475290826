export const EN_LOCALE = {
  address: {
    errors: {
      hasActiveSubscription: "Address used for recurring order",
      reschedulingNotPossible: "Sorry, we cannot reschedule this order.",
      addressIsNotServiceable: (storeName?: string) =>
        `${
          storeName || "We"
        } cannot deliver to this address. Please choose a different address`,
      validationPrimary: "Address validation error",
      notFound: "Address is not found",
    },
  },
  business: {
    errors: {
      businessCapabilitiesCouldNotBeFetched:
        "We were unable to fetch the delivery and self-serve capabilities of this business, please refresh and try again",
    },
  },
  button: {
    addPromo: "Add Promo",
    applyCredit: "Apply Credit",
    applyPromo: "Apply Promo",
    applyRewards: "Apply Rewards",
    cancelOrder: "Cancel Order",
    closeDrawer: "Close drawer",
    continue: "Continue",
    decreaseBags: "Decrease the number of bags",
    edit: "Edit",
    email: "Email",
    enterCardAndPay: "Enter card & pay",
    gotIt: "Got it",
    illPickupInStore: "I'll pickup in-store",
    increaseBags: "Increase the number of bags",
    manage: "Manage",
    ooboCTA: "Please click submit to order on behalf of customer.",
    orderAgain: "Order Again",
    pay: "Pay",
    removeCredit: "Remove Credit",
    removePromo: "Remove Promo",
    removeRewards: "Remove Rewards",
    resendOTP: "Send code again",
    save: "Save",
    savePreferences: "Save Preferences",
    seeDeliveryOptions: "See delivery options",
    sendVerificationCode: "Send Verification Code",
    scheduleDelivery: "Schedule Delivery",
    showVerificationCode: "Show Verification Code",
    skip: "Skip",
    submitOTP: "Submit Code",
    update: "Update",
  },
  customer: {
    errors: {
      lastOrderTypeCouldNotBeFetched:
        "We were unable to fetch the last order type for this customer, please refresh and try again",
    },
  },
  label: {
    accountCredited: "Account Credited",
    accountVerification: "Verify Account",
    addCarePreferences: "Add care preferences",
    addOrderNotes: "Add order notes",
    addressDetails: "Address Details",
    applePay: "Apple Pay",
    addAddress: "Add address",
    address: "Address",
    appliedRoundUp: "Applied Round Up",
    arrow: "Arrow",
    arrowDown: "Down arrow",
    arrowLeft: "Left arrow",
    back: "Back",
    balanceDue: "Balance Due",
    canceled: "Canceled",
    carePreferences: "Care preferences",
    category: "Category",
    changePaymentMethod: "Change Payment Method",
    checkMark: "Check mark",
    chevron: "Chevron",
    clock: "Clock",
    closeAddressDetailsDialog: "Close Address Details Dialog",
    clothes: "Clothes",
    clothesWashing: "Clothes washing",
    commercialDcaLicense: "DCA # - Commercial:",
    confirmAddress: "Confirm Address",
    creditApplied: "Credit applied",
    creditCard: "Credit Card",
    cross: "Cross",
    custom: "Custom",
    dcaLicense: "DCA # - Retail:",
    deliveredAt: "Delivered at",
    delivery: "Delivery",
    deliveryCar: "Delivery car",
    deliveryProcess: "Delivery process",
    deliveryScheduled: "Delivery Scheduled",
    details: "Details",
    discountApplied: "Discount applied",
    dryCleaning: "Dry Cleaning",
    editAddress: "Edit Address",
    eachWay: "each way",
    email: "Email",
    enterCardAndPay: "Enter card & pay",
    estimatedArrival: "Estimated arrival",
    exclamationMark: "Exclamation mark",
    firstName: "First Name",
    free: "Free",
    frequency: "Frequency",
    geolocationPin: "Geolocation pin",
    googlePay: "Google Pay",
    hangDry: "Hang Dry",
    information: "Information",
    itsTimeToScheduleYourDelivery: "It's time to schedule your delivery.",
    lastName: "Last Name",
    laundryBasket: "Laundry basket",
    leftAtTheDoor: "Left At The Door",
    leftChevron: "Left chevron",
    loadedClosedWashingMachine: "Loaded closed washing machine",
    loadedOpenWashingMachine: "Loaded open washing machine",
    logo: "Logo",
    loupe: "Loupe",
    manageOrder: "Manage Order",
    markedCheckbox: "Marked checkbox",
    markedForInvoicing: "Marked for invoicing",
    minimalPrice: (price: string) => `(Minimum ${price})`,
    money: "Money",
    navigationListItem: "Navigation list item",
    navigationMenu: "Navigation menu",
    nextDay: "Next day",
    off: "off",
    order: "Order",
    orderLocation: "Order Location",
    orderNotes: "Order Notes",
    orderNotesExample:
      "e.g. The grey sweater has a stain on it, please use stain remover.",
    orderTotal: "Order Total",
    paidViaInvoice: "Paid via Invoice",
    payment: "Payment",
    paymentCard: "Payment Card",
    paymentCardBrand: "Payment card brand",
    paymentMethod: "Payment Method",
    pencil: "Pencil",
    phone: "Phone",
    pickup: "Pickup",
    pileOfLaundry: "Pile of laundry",
    plus: "Plus",
    poweredByCents: "Powered by Cents",
    preferences: "Care Preferences",
    previousDay: "Previous day",
    promo: "Promo",
    promoApplied: "Promo Applied",
    promoCode: "Promo Code",
    qrCode: "QR code",
    pud: "Pickup & Delivery",
    recurringOrderDiscount: "Recurring Order Discount",
    redX: "Red X",
    rememberTipForRecurring: "Remember tip for recurring orders",
    rewardsApplied: "Rewards applied",
    rightChevron: "Right chevron",
    savedCreditCards: "Saved credit cards",
    scheduleLater: "Schedule later",
    searchAddress: "Search address",
    selectPaymentMethod: "Select Payment Method",
    service: "Service",
    serviceFee: "Service fee",
    settings: "Settings",
    showStoreInfo: "Store Info",
    storeInfo: "Store Info",
    subtotal: "Subtotal",
    tax: "Tax",
    // To Be Determined
    TBD: "TBD",
    textWhenReady: (turnAround?: number | null) =>
      turnAround
        ? `You’ll receive a text when it’s ready (est ${turnAround}hrs)`
        : "You’ll receive a text when it’s ready",
    threeDots: "Three dots",
    threeHorizontalLines: "Three horizontal lines",
    tip: "Tip",
    totalDue: "Total Due",
    totalPaid: "Total Paid",
    transportationProviderLogo: "Transportation provider logo",
    trashCan: "Trash can",
    truck: "Truck",
    unloadedWashingMachine: "Unloaded washing machine",
    unmarkedCheckbox: "Unmarked checkbox",
    washingMachine: "Washing machine",
    washingMachineUnloading: "Washing machine unloading",
    xIcon: "X",
    yourDriver: "Your Driver",
    yourLaundryIsReady: "Your laundry is ready!",
    yourOrder: "Your Order",
  },
  messages: {
    accountLinkingIssue: "Could not check your account linked to your phone number",
    accountVerification: "Verify Your Account",
    addTipForTheStaff: "Add a tip for the staff",
    addPaymentMethod: "Please, add new payment method",
    applyPromoCode: "Apply Promo Code",
    creditsRemoved: "Credits removed!",
    codeSent: "Code sent!",
    genericError: "Something went wrong",
    howMuchTip: "How much would you like to tip the staff?",
    loginRequirement: "Verify your account to login",
    optInCall: "I’ve opted back in",
    orderCanceled: "Order canceled!",
    orderPaidSuccessfully: "Order paid successfully!",
    otpCTA: "Enter the 6-digit code we sent to",
    otpInstruction: "We’ll send you a text with a code to create your account.",
    recurringOrderUpdated: "Recurring order updated",
    paymentFailed: "Payment Failed! Please try again.",
    phoneNumberCTA: "What’s your phone number?",
    phoneNumberInput: "Phone number input",
    phoneNumberPurpose:
      "We’ll only use it to verify your identity and provide updates about your order.",
    preferencesVisibilityDescription:
      "We’ll remember your preferences. You can always access them via Account Settings and when checking out.",
    preferencesVisibilityOption: "Don't show me this page again",
    promoRemoved: "Promo Removed!",
    rewardsApplied: "Rewards applied!",
    rewardsPointsRemoved: "Rewards points removed!",
    welcomeBack: "Welcome back,",
  },
  order: {
    errors: {
      submitError: "Could not submit the order!",
      updateError: "Error while updating your order",
      rewardsApplyingError: "Something went wrong while applying rewards.",
    },
    labels: {
      deliveryScheduled: "Delivery Scheduled",
      driverHeaded: "Driver is headed your way",
      driverIsOnTheWay: "Driver is on the way",
      driverLeftOrderAtDoor: "The driver left your order at your door",
      laundryIsEnRoute: "Laundry is en route for processing",
      orderComplete: "Order Complete",
      orderHasBeenLeftAtDoor: "Your order has been left at your door",
      pickedUp: "Picked Up",
      pickupScheduled: "Pickup scheduled",
      processing: "Processing",
      proofOfDelivery: "Proof of delivery",
      readyForPickupInStore: "Ready for pickup in-store",
      yourNextPickup: "Your Next Pickup:",
    },
  },
  payment: {
    errors: {
      paymentIntentCaptureError:
        "Payment declined. Please use a different payment method.",
    },
    messages: {
      paymentCondition:
        "Your card will not be charged until your order is picked up in-store or ready for delivery.",
    },
  },
  machines: {
    errors: {
      additionalVendError:
        "There was an issue in processing your additional payment to start your machine. You have not been charged, but please try again.",
    },
  },
};
