import React, {useCallback, useEffect, useState} from "react";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import classNames from "classnames";
import {useFlags} from "launchdarkly-react-client-sdk";
import get from "lodash/get";
import {toast} from "react-toastify";
import {Box, Flex, Image, Text} from "rebass/styled-components";
import "components/account/AccountPayment/PaymentCards/AccountPayment.styles.scss";
import CardsList from "components/account/AccountPayment/PaymentCards/components/CardsList";
import {A11yButton} from "components/common/buttons/A11yButton";
import {Button} from "components/common/buttons/Button";
import {Drawer} from "components/common/drawers/Drawer";
import {Section} from "components/common/layouts/Section";
import {SectionLayout} from "components/common/layouts/SectionLayout";
import {errorToastOptions, ToastError} from "components/newOrder/common/Toast";
import {useThemeValues} from "hooks/useThemeValues";
// APIs
import {
  deleteCustomerPaymentMethods,
  getCustomerPaymentMethods,
  updateCustomerDefaultPaymentMethod,
} from "api/paymentMethods";
import {EN_LOCALE} from "locales/en";
// Assets
import {
  ApplePayIcon,
  BlueCheckIconSmall,
  GooglePayIcon,
  IconAddCard,
} from "../../assets/images";
// Hooks
import useRecaptchaToken from "../../hooks/useRecaptchaToken";
// Utils
import {STRIPE_PUBLIC_KEY} from "../../utils/config";
// Components
import {FullScreenModalForm} from "../common";
import AddPaymentMethod from "./AddPaymentMethod";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const PaymentMethodList = (props) => {
  const {manageOrderV2} = useFlags();

  const {
    onClose,
    customer,
    paymentMethod,
    onSave,
    order,
    paymentMethodList,
    newOrder,
    onNewPaymentMethod,
    isDockOpen,
    isSubscriptionOrder,
    totalAmount,
    businessName,
    machine,
    onDigitalWalletSelection,
  } = props;
  const stripe = useStripe();

  const {primaryColor} = useThemeValues();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(
    paymentMethodList ? paymentMethodList : null
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethod ? paymentMethod : null
  );
  const [selectedPaymentMethodToken, setSelectedPaymentMethodToken] = useState(
    paymentMethod ? paymentMethod.paymentMethodToken : null
  );
  const [showNewPaymentMethod, setShowNewPaymentMethod] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [paymentRequestToken, setPaymentRequestToken] = useState();
  const [paymentRequestType, setPaymentRequestType] = useState();
  const [paymentMethodTokensIdsToDelete, setPaymentMethodTokensIdsToDelete] = useState(
    []
  );
  const [digitalWalletType, setDigitalWalletType] = useState(null);
  const ctaText = "Save";

  const recaptchaToken = useRecaptchaToken("PaymentMethodList");

  useEffect(() => {
    setSelectedPaymentMethod(paymentMethod || null);
    setSelectedPaymentMethodToken(paymentMethod?.paymentMethodToken);
  }, [paymentMethod]);

  useEffect(() => {
    if (paymentMethodList && paymentMethod?.paymentMethodToken) {
      setPaymentMethods(
        paymentMethodList.map((pm) => {
          const isDefault =
            pm.notSaved || pm.paymentMethodToken === paymentMethod?.paymentMethodToken;
          return {...pm, isDefault};
        })
      );
    } else {
      setPaymentMethods(paymentMethodList || null);
    }
  }, [paymentMethodList, paymentMethod]);

  const handleDeletePaymentMethod = (pm) => {
    if (pm.hasSubscriptions) {
      toast.error(<ToastError message="Payment used for recurring order" />, {
        ...errorToastOptions,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (pm.hasServiceOrders || pm.notSaved) {
      toast.error(<ToastError message="Payment used for active order" />, {
        ...errorToastOptions,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (pm.paymentMethodToken === paymentMethod?.paymentMethodToken) {
      toast.error(<ToastError message="Payment used for current order" />, {
        ...errorToastOptions,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      setPaymentMethods((prevState) =>
        prevState.filter((item) => item.paymentMethodToken !== pm.paymentMethodToken)
      );
      setPaymentMethodTokensIdsToDelete((prevState) => [
        ...prevState,
        pm.paymentMethodToken,
      ]);
    }
  };

  const handlePaymentMethodSelection = (paymentMethodToken) => {
    setPaymentMethods((prevState) =>
      prevState.map((item) => {
        const isDefault = item.paymentMethodToken === paymentMethodToken;
        return {...item, isDefault};
      })
    );
    const paymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.paymentMethodToken === paymentMethodToken
    );
    selectPaymentMethod(paymentMethod);
  };

  /**
   * Get the list of customer payment methods on file
   */
  const getPaymentMethods = useCallback(async () => {
    try {
      setLoading(true);
      const paymentMethodData = await getCustomerPaymentMethods();
      setPaymentMethods(paymentMethodData.data.paymentMethods);
    } catch (error) {
      setErrorMessage(get(error, "response.data.error", error.message));
    } finally {
      setLoading(false);
    }
  }, []);

  const getTotalAmount = useCallback(() => {
    if (order?.balanceDue) {
      return order.balanceDue;
    }

    if (totalAmount) {
      return totalAmount;
    }

    return 0;
  }, [order, totalAmount]);

  const getTransactionLabel = useCallback(() => {
    if (order?.store) {
      return `${order.store.name} for ${order.orderCodeWithPrefix}`;
    }

    if (machine?.store) {
      return `${machine.store.name} for ${machine.name} Cycle`;
    }

    if (businessName) {
      return `${businessName} for services`;
    }

    return "Garment care services";
  }, [order, machine, businessName]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!paymentMethods) {
      getPaymentMethods();
    }
  }, [getPaymentMethods, paymentMethods]);

  useEffect(() => {
    if (stripe) {
      const totalToUse = getTotalAmount();
      const balanceDue = Number(totalToUse).toFixed(2);
      const stripeBalanceDue = Number(balanceDue * 100).toFixed(2);
      const finalStripeTotal = Number(stripeBalanceDue);

      //do not show Apply / Google Pay option if order balance due is negative
      if (totalToUse >= 0) {
        const pr = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: getTransactionLabel(),
            amount: finalStripeTotal,
            pending: totalToUse === 0,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
          if (result) {
            const browser = result.applePay ? "Apple Pay" : "Google Pay";
            setPaymentRequestType(browser);
            setPaymentRequest(pr);
            setDigitalWalletType(result.applePay ? "apple" : "google"); // for displaying the proper apple/google pay icon
          }
        });
      }
    }
  }, [order, stripe, getTotalAmount]);

  /**
   * Store selected payment method information in state for an already-configured PaymentMethod
   *
   * @param {Object} paymentMethod
   */
  const selectPaymentMethod = (paymentMethod) => {
    setSelectedPaymentMethodToken(paymentMethod.paymentMethodToken);
    setSelectedPaymentMethod(paymentMethod);
  };

  /**
   * Format the newly saved card to fit within our API requirements
   *
   * @param {Object} paymentData
   */
  const formatNewCard = (paymentData) => {
    const newData = {
      paymentMethodToken: paymentData.payment.id,
      type: paymentData.payment.card.funding,
      provider: "stripe",
      centsCustomerId: customer.centsCustomerId,
      brand: paymentData.payment.card.brand,
      last4: paymentData.payment.card.last4,
    };
    const newArray = [...paymentMethods, newData];

    setSelectedPaymentMethod(newData);
    setPaymentMethods(newArray);
    setSelectedPaymentMethodToken(newData.paymentMethodToken);
  };

  /**
   * Listen for paymentRequest changes for when Apple/Google Pay card is selected
   */
  if (paymentRequest) {
    paymentRequest.on("paymentmethod", function (event) {
      const paymentData = event.paymentMethod;
      const newData = {
        paymentMethodToken: paymentData.id,
        type: paymentData.card.funding,
        provider: "stripe",
        centsCustomerId: customer.centsCustomerId,
        brand: paymentData.card.brand,
        last4: paymentData.card.last4,
      };
      setSelectedPaymentMethod(newData);
      setSelectedPaymentMethodToken(newData.paymentMethodToken);
      setPaymentRequestToken(newData.paymentMethodToken);
      onDigitalWalletSelection(digitalWalletType);
      onSave(newData, paymentMethods);
      event.complete("success");
    });
  }

  const submitClickHandler = async () => {
    try {
      const defaultPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.isDefault
      );

      if (defaultPaymentMethod && !defaultPaymentMethod.notSaved) {
        // update the default payment method only if it has been saved.
        await updateCustomerDefaultPaymentMethod(defaultPaymentMethod.paymentMethodToken);
      }

      if (paymentMethodTokensIdsToDelete.length) {
        await deleteCustomerPaymentMethods(paymentMethodTokensIdsToDelete);
      }
      setPaymentMethodTokensIdsToDelete([]);
      await onSave(selectedPaymentMethod, paymentMethods);
    } catch (error) {
      toast.error(<ToastError primaryMessage={error.text} />, {
        ...errorToastOptions,
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  /**
   * Render the list of payment methods
   */
  const renderPaymentMethods = () => {
    return (
      <Box
        width="100%"
        m={newOrder ? "" : "auto"}
        px={manageOrderV2 ? 0 : "10px"}
        height={"100%"}
        overflowX={"hidden"}
      >
        <SectionLayout>
          <Section
            title={EN_LOCALE.label.savedCreditCards}
            variant={manageOrderV2 ? "compact" : "deprecated"}
          >
            <CardsList
              currentPaymentMethods={paymentMethods}
              handleDeletePaymentMethod={handleDeletePaymentMethod}
              handlePaymentMethodSelection={handlePaymentMethodSelection}
            />
          </Section>
        </SectionLayout>
        {paymentRequest && (
          <A11yButton
            className={classNames("card-item", {
              "third-party-card-provider": manageOrderV2,
            })}
            onAction={(e) => {
              e.stopPropagation();
              paymentRequest.show();
            }}
            aria-label={EN_LOCALE.label.selectPaymentMethod}
          >
            <div className="card-info">
              <Image
                src={paymentRequestType === "Apple Pay" ? ApplePayIcon : GooglePayIcon}
                {...styles.button.content.image}
                alt={
                  paymentRequestType === "Apple Pay"
                    ? EN_LOCALE.label.applePay
                    : EN_LOCALE.label.googlePay
                }
              />
              <Text variant="blackText">{paymentRequestType}</Text>
            </div>
            <div className="card-actions">
              {selectedPaymentMethodToken === paymentRequestToken && (
                <Image
                  src={BlueCheckIconSmall}
                  alt={EN_LOCALE.label.paymentCard}
                  className="default-card"
                />
              )}
            </div>
          </A11yButton>
        )}
        <A11yButton
          className={classNames("add-credit-card-wrapper", {
            "payment-methods-wrapper": manageOrderV2,
          })}
          onAction={onNewPaymentMethod}
        >
          <p>Add Credit Card</p>
          <IconAddCard fill={primaryColor} />
        </A11yButton>
      </Box>
    );
  };

  /**
   * If the parent component is from the new order/online order form, render inside the Dock
   */
  const renderNewOrderView = () => {
    return (
      <Drawer
        title={manageOrderV2 ? EN_LOCALE.label.payment : EN_LOCALE.label.paymentMethod}
        isShowingDrawer={isDockOpen}
        closeDrawer={onClose}
        loading={loading}
      >
        <Flex
          {...styles.paymentMethodsBody}
          {...(manageOrderV2 ? styles.paymentMethodContainer : {})}
        >
          {renderPaymentMethods()}
          <Flex {...styles.footer.wrapper}>
            <Button
              disabled={!selectedPaymentMethod}
              onClick={async () => {
                if (!recaptchaToken)
                  return setErrorMessage(
                    "Recaptcha verification failed. Please exit and try again."
                  );
                await submitClickHandler();
              }}
            >
              {ctaText}
            </Button>
          </Flex>
          {errorMessage && <Text variant="errorMessage">{errorMessage}</Text>}
        </Flex>
      </Drawer>
    );
  };

  /**
   * If the parent component is not from the new order/online order form, render inside FullScreen
   */
  const renderExistingOrderView = () => {
    return (
      <>
        {!showNewPaymentMethod && (
          <FullScreenModalForm
            header={manageOrderV2 ? EN_LOCALE.label.payment : "Payment Method"}
            onClose={onClose}
            btnLabel={ctaText}
            loading={loading}
            onSubmit={async () => {
              if (!recaptchaToken)
                return setErrorMessage(
                  "Recaptcha verification failed. Please exit and try again."
                );
              await submitClickHandler();
            }}
            footerBtnStyles={styles.ctaButtonText}
            variant={manageOrderV2 ? "primary" : "deprecated"}
          >
            {renderPaymentMethods()}
            {errorMessage && <Text variant="errorMessage">{errorMessage}</Text>}
          </FullScreenModalForm>
        )}
        {showNewPaymentMethod && (
          <Elements stripe={stripePromise}>
            <AddPaymentMethod
              goBack={() => {
                window.scrollTo(0, 0);
                setShowNewPaymentMethod(false);
              }}
              onSave={(paymentInfo) => {
                formatNewCard(paymentInfo);
                setShowNewPaymentMethod(false);
              }}
              isSubscriptionOrder={isSubscriptionOrder}
            />
          </Elements>
        )}
      </>
    );
  };

  return <>{newOrder ? renderNewOrderView() : renderExistingOrderView()}</>;
};

const styles = {
  button: {
    wrapper: {
      fontFamily: "Inter",
      width: "100%",
      height: ["3rem", "3.5rem"],
      mb: "20px",
    },
    content: {
      wrapper: {
        alignItems: "center",
        fontWeight: 600,
        color: "NEW_TEXT_PRIMARY_BLUE",
      },
      image: {
        mr: "13px",
        pl: "6px",
      },
    },
  },
  newCardButton: {
    wrapper: {
      sx: {border: "1px dashed black"},
    },
    content: {
      wrapper: {
        justifyContent: "space-between",
      },
    },
  },
  ctaButtonText: {
    sx: {
      textTransform: "uppercase",
      backgroundColor: "#0567d5",
    },
    fontSize: 16,
  },
  addCreditCardWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "24px",
    marginBottom: "24px",
    padding: "9px 1px",
    cursor: "pointer",

    p: {
      margin: 0,
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "14px",
      letterSpacing: "0.0125em",
      paddingRight: "4px",
    },
  },
  footer: {
    wrapper: {
      mt: "auto",
      height: "4rem",
      px: "2rem",
      alignItems: "center",
      justifyContent: "center",
      // mb: "2rem",
      width: "100%",
    },
    button: {
      width: ["100%", "100%", "100%", "100%"],
      height: ["3.5rem", "4rem"],
      fontSize: [3, 4],
    },
  },
  paymentMethodsBody: {
    sx: {
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "85%",
    },
  },
  paymentMethodContainer: {
    sx: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: 1,
      paddingBottom: "16px",
      gap: "24px",
      overflow: "auto",
    },
  },
  italicText: {
    sx: {
      fontStyle: "italic",
      fontSize: "12px",
      color: "TEXT_LIGHT_GREY",
      fontWeight: 600,
    },
    pl: "6px",
  },
};

export default PaymentMethodList;
