import React from "react";
import {Text, Image, Box} from "rebass/styled-components";
import {Layout} from "components/common/layouts/Layout";
import {EN_LOCALE} from "locales/en";
import {ClothesPile} from "assets/images";

export const ElementNotFound = () => (
  <Layout>
    <Box {...styles.container}>
      <Image src={ClothesPile} {...styles.image} alt={EN_LOCALE.label.pileOfLaundry} />
      <Text {...styles.title}>Item Not Found</Text>
    </Box>
  </Layout>
);

const styles = {
  container: {
    marginTop: "10vh",
    sx: {
      textAlign: "center",
    },
  },
  image: {
    width: "35%",
    minWidth: "200px",
  },
  title: {
    paddingTop: "24px",
    fontSize: "24px",
    color: "CENTS_BLUE",
    sx: {
      textAlign: "center",
    },
  },
};
