import {createSelector} from "reselect";
import {getCustomerData} from "../selectors";

export const getCentsCustomer = createSelector(
  getCustomerData,
  (state) => state.centsCustomer
);

export const getCentsCustomerId = createSelector(
  getCustomerData,
  (state) => state.centsCustomer?.id
);

export const getCustomerInfoFetchingStatus = createSelector(
  getCustomerData,
  (state) => state.apiRequests.customerInfo.fetchingStatus
);
