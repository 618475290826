import {FC} from "react";
import {A11yButton} from "components/common/buttons/A11yButton";
import {ButtonVariant, Button} from "components/common/buttons/Button";
import {SectionActionButton} from "components/common/buttons/SectionActionButton";
import {EN_LOCALE} from "locales/en";
import styles from "./carePreferences.module.scss";

export const CarePreferences: FC<{
  preferences: string;
  variant?: "primary" | "deprecated";
  onEdit: () => void;
}> = ({preferences, variant = "deprecated", onEdit}) => {
  return variant === "primary" ? (
    <A11yButton className={styles.carePreferencesWrapper} onAction={onEdit}>
      <div>
        <h3>{EN_LOCALE.label.carePreferences}</h3>
        <SectionActionButton
          variant={variant === "primary" ? "primary" : "underlined"}
          className={styles.chevron}
        />
      </div>
      {preferences ? (
        <p>{preferences}</p>
      ) : (
        <p className={styles.placeholder}>{EN_LOCALE.label.addCarePreferences}</p>
      )}
    </A11yButton>
  ) : (
    <div className={styles.carePreferencesWrapper}>
      <div>
        <h3>Care preferences</h3>
        <Button
          variant={ButtonVariant.UNDERLINED}
          className={styles.editButton}
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <p>{preferences}</p>
    </div>
  );
};
