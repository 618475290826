import {FC} from "react";
import {A11yButton} from "components/common/buttons/A11yButton";
import {ButtonVariant, Button} from "components/common/buttons/Button";
import {SectionActionButton} from "components/common/buttons/SectionActionButton";
import {EN_LOCALE} from "locales/en";
import styles from "./orderNotes.module.scss";

export const OrderNotes: FC<{
  notes: string;
  variant?: "primary" | "deprecated";
  onEdit: () => Promise<void> | void;
}> = ({notes, variant, onEdit}) => {
  return variant === "primary" ? (
    <A11yButton className={styles.orderNotesWrapper} onAction={onEdit}>
      <div>
        <h3>{EN_LOCALE.label.orderNotes}</h3>
        <SectionActionButton variant={variant} className={styles.chevron} />
      </div>
      {notes ? (
        <p>{notes}</p>
      ) : (
        <p className={styles.placeholder}>{EN_LOCALE.label.addOrderNotes}</p>
      )}
    </A11yButton>
  ) : (
    <div className={styles.orderNotesWrapper}>
      <div>
        <h3>Order notes</h3>
        <Button
          variant={ButtonVariant.UNDERLINED}
          className={styles.editButton}
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      {notes && <p>{notes}</p>}
    </div>
  );
};
