import React, {useCallback, useEffect, useMemo, useState} from "react";
import cx from "classnames";
import ChooseAmount from "features/payment/availableCredit/components/chooseAmount";
import type {IAddCredit} from "features/payment/availableCredit/types";
import {useParams} from "react-router-dom";
import {Box, Button, Heading, Text} from "rebass/styled-components";
import {MachineCardsList} from "components/SelfServeHome/MachineCardsList/MachineCardsList";
import {BlockingLoader} from "components/common/BlockingLoader/BlockingLoader";
import {
  SelfServeOrderModalComponents,
  SelfServeOrderModal,
} from "components/common/SelfServeOrderModal/SelfServeOrderModal";
import {Layout} from "components/common/layouts/Layout";
import {EncodedBusinessLevelProvider} from "components/common/providers/EncodedBusinessLevelProvider";
import useToggle from "hooks/useToggle";
import {useAppDispatch, useAppSelector} from "state/redux/hooks";
import {getCentsCustomer} from "state/redux/slices/customer/selectors/credentialsSelector";
import {orderSelectors, orderThunks} from "state/redux/slices/order";
import {incrementalIdDecrypt} from "utils/encryption/encodedIdDecrypt";
import {numberToDollarString} from "utils/payment";
import {useAddCredits} from "api/queries/useAddCredits";
import {useCustomerBalance} from "api/queries/useCustomerBalance";
import {FETCHING_STATUS} from "constants/api";
import {ERROR_MESSAGES} from "constants/constants";
import {ReactComponent as PlusIcon} from "assets/images/Icon_Plus.svg";
import {BusinessLevelParams} from "types/routes";
import {CustomLogo} from "../common";
import {NewMachineModal} from "./NewMachineModal/NewMachineModal";
import styles from "./SelfServeHome.module.scss";

export const SelfServeHome: React.FC = () => {
  const [showAddFundsModal, toggleShowAddFundsModal] = useState(false);
  const [selfServeHash, setSelfServeHash] = useState<string | null>(null);
  const {isOpen: showSelfServeOrderModal, toggle: toggleShowSelfServeOrderModal} =
    useToggle();

  const dispatch = useAppDispatch();
  const {isOpen: isOpenNewMachineModal, toggle: toggleOpenNewMachineModal} =
    useToggle(false);

  const {
    data: {
      savedCustomerAddresses,
      paymentMethods,
      businessSettings,
      theme: {primaryColor},
    },
    fetchingStatus,
  } = useAppSelector(orderSelectors.getOrderInitialData) || {};

  const {firstName: customerFirstName, id: centsCustomerId} =
    useAppSelector(getCentsCustomer) || {};

  const {encodedBusinessLevelId} = useParams<BusinessLevelParams>();

  const decodedBusinessId = useMemo(
    () => Number(incrementalIdDecrypt(encodedBusinessLevelId)),
    [encodedBusinessLevelId]
  );

  useEffect(() => {
    if (fetchingStatus === FETCHING_STATUS.INITIAL) {
      dispatch(
        orderThunks.getOrderInitialData({
          businessId: encodedBusinessLevelId,
        })
      );
    }
  }, [fetchingStatus, encodedBusinessLevelId, dispatch]);

  const {
    data: {balanceInDollars} = {},
    isLoading: isBalanceLoading,
    error: balanceError,
    refetch: refetchBalance,
  } = useCustomerBalance({
    encodedBusinessId: encodedBusinessLevelId,
  });

  const handleNewMachine = useCallback(
    (hash: string) => {
      toggleOpenNewMachineModal();

      setSelfServeHash(hash);
      toggleShowSelfServeOrderModal();
    },
    [toggleOpenNewMachineModal, toggleShowSelfServeOrderModal]
  );

  const {isLoading: isAddCreditsLoading, mutate: addCredits} = useAddCredits({
    refetch: refetchBalance,
  });

  const onSendAmount = ({credits, paymentMethodToken}: IAddCredit) => {
    const decodedBusinessId = incrementalIdDecrypt(encodedBusinessLevelId);

    addCredits({
      credits,
      paymentMethodToken,
      businessId: decodedBusinessId as number,
    });
  };

  return (
    <EncodedBusinessLevelProvider>
      <Layout customHeaderClass={styles.iceBgColor} businessSettings={businessSettings}>
        <SelfServeOrderModal
          modalType={SelfServeOrderModalComponents.SELF_SERVE}
          hash={selfServeHash}
          isOpen={showSelfServeOrderModal}
          toggle={toggleShowSelfServeOrderModal}
          balanceInDollars={balanceInDollars}
          customerInfo={{
            id: centsCustomerId as number,
            paymentMethods,
            addresses: savedCustomerAddresses,
          }}
          refetchBalance={refetchBalance}
        />
        <div className={styles.homeContainer}>
          <div className={styles.homeContent}>
            <Box className={styles.logoContainer}>
              <CustomLogo />
            </Box>
            {customerFirstName && (
              <Heading className={styles.greetings}>Hi, {customerFirstName}!</Heading>
            )}
            <Box
              className={cx(
                styles.balanceContainer,
                (isBalanceLoading || isAddCreditsLoading) &&
                  styles.balanceContainerLoading
              )}
              onClick={() => toggleShowAddFundsModal(true)}
            >
              {(isBalanceLoading || isAddCreditsLoading) && <BlockingLoader />}
              {balanceError ? (
                <Text className={styles.errorMessage}>
                  {balanceError.response?.data?.error ?? ERROR_MESSAGES.CUSTOMER_BALANCE}
                </Text>
              ) : (
                <>
                  {balanceInDollars ? (
                    <Text>Balance</Text>
                  ) : (
                    <button className={styles.addFundsBtn}>Add Funds</button>
                  )}
                  <Text>{numberToDollarString(balanceInDollars ?? 0)}</Text>
                </>
              )}
            </Box>
            <MachineCardsList
              encodedBusinessId={encodedBusinessLevelId}
              centsCustomerId={centsCustomerId as number}
              balanceInDollars={balanceInDollars}
              primaryColor={primaryColor}
              customerInfo={{
                id: centsCustomerId as number,
                paymentMethods,
                addresses: savedCustomerAddresses,
              }}
              refetchBalance={refetchBalance}
            />
            <NewMachineModal
              isOpen={isOpenNewMachineModal}
              toggle={toggleOpenNewMachineModal}
              onMachineAdd={handleNewMachine}
              businessId={decodedBusinessId}
            />
            <Button
              className={styles.addMachineBtn}
              onClick={toggleOpenNewMachineModal}
              style={{backgroundColor: primaryColor}}
            >
              New Machine
              <PlusIcon />
            </Button>
            {!!centsCustomerId && (
              <ChooseAmount
                customer={{
                  id: centsCustomerId,
                  paymentMethods,
                  addresses: savedCustomerAddresses,
                }}
                showModal={showAddFundsModal}
                toggleShowModal={toggleShowAddFundsModal}
                sendAmount={onSendAmount}
              />
            )}
          </div>
        </div>
      </Layout>
    </EncodedBusinessLevelProvider>
  );
};
