import {CSSProperties, FC} from "react";
import classNames from "classnames";
import {Box, Flex, Text} from "rebass/styled-components";

export const Loader: FC<{
  fullScreen?: boolean;
  loaderColor?: string;
  style?: CSSProperties;
  width?: string;
  customHeader?: string;
  variant?: "primary" | "compact";
}> = ({
  loaderColor = "primary",
  style,
  width,
  customHeader,
  fullScreen = true,
  variant = "primary",
}) => {
  return (
    <Flex
      flexDirection="column"
      fontFamily="primary"
      style={
        fullScreen
          ? {
              height: "var(--app-height)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(255, 255, 255, 1)",
              position: "absolute",
              zIndex: 999999999,
              top: "0",
              left: "0",
              ...style,
            }
          : {}
      }
      width={width || "100%"}
      className="loader"
    >
      <div
        className={classNames("spinner", {
          compact: variant === "compact",
        })}
      >
        <Box className="rect1" bg={loaderColor} />
        <Box className="rect2" bg={loaderColor} />
        <Box className="rect3" bg={loaderColor} />
        <Box className="rect4" bg={loaderColor} />
        <Box className="rect5" bg={loaderColor} />
      </div>
      {variant === "compact" ? (
        <></>
      ) : (
        <Text className="color-transition" mt="1rem" color={loaderColor}>
          {customHeader || "Loading"}
        </Text>
      )}
    </Flex>
  );
};
